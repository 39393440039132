exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-5-a-framework-jsx": () => import("./../../../src/pages/5A-framework.jsx" /* webpackChunkName: "component---src-pages-5-a-framework-jsx" */),
  "component---src-pages-blog-5-tools-that-every-company-should-invest-in-to-reduce-employee-attrition-jsx": () => import("./../../../src/pages/blog/5-tools-that-every-company-should-invest-in-to-reduce-employee-attrition.jsx" /* webpackChunkName: "component---src-pages-blog-5-tools-that-every-company-should-invest-in-to-reduce-employee-attrition-jsx" */),
  "component---src-pages-blog-do-you-belong-in-hr-if-youve-asked-yourself-this-youre-not-alone-jsx": () => import("./../../../src/pages/blog/do-you-belong-in-hr-if-youve-asked-yourself-this-youre-not-alone.jsx" /* webpackChunkName: "component---src-pages-blog-do-you-belong-in-hr-if-youve-asked-yourself-this-youre-not-alone-jsx" */),
  "component---src-pages-blog-global-employee-engagement-indicator-and-how-to-increase-it-jsx": () => import("./../../../src/pages/blog/global-employee-engagement-indicator-and-how-to-increase-it.jsx" /* webpackChunkName: "component---src-pages-blog-global-employee-engagement-indicator-and-how-to-increase-it-jsx" */),
  "component---src-pages-blog-how-logotherapy-helps-in-setting-the-right-company-culture-jsx": () => import("./../../../src/pages/blog/how-logotherapy-helps-in-setting-the-right-company-culture.jsx" /* webpackChunkName: "component---src-pages-blog-how-logotherapy-helps-in-setting-the-right-company-culture-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-mood-index-what-is-it-and-how-can-you-measure-it-jsx": () => import("./../../../src/pages/blog/mood-index-what-is-it-and-how-can-you-measure-it.jsx" /* webpackChunkName: "component---src-pages-blog-mood-index-what-is-it-and-how-can-you-measure-it-jsx" */),
  "component---src-pages-blog-nasch-vs-glint-viva-a-quick-yet-detailed-walkthrough-jsx": () => import("./../../../src/pages/blog/nasch-vs-glint-viva-a-quick-yet-detailed-walkthrough.jsx" /* webpackChunkName: "component---src-pages-blog-nasch-vs-glint-viva-a-quick-yet-detailed-walkthrough-jsx" */),
  "component---src-pages-blog-presenteeism-how-it-affects-productivity-and-3-ways-to-tackle-it-jsx": () => import("./../../../src/pages/blog/presenteeism-how-it-affects-productivity-and-3-ways-to-tackle-it.jsx" /* webpackChunkName: "component---src-pages-blog-presenteeism-how-it-affects-productivity-and-3-ways-to-tackle-it-jsx" */),
  "component---src-pages-blog-what-is-enps-and-why-does-it-matter-jsx": () => import("./../../../src/pages/blog/what-is-enps-and-why-does-it-matter.jsx" /* webpackChunkName: "component---src-pages-blog-what-is-enps-and-why-does-it-matter-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integration-jsx": () => import("./../../../src/pages/integration.jsx" /* webpackChunkName: "component---src-pages-integration-jsx" */),
  "component---src-pages-onboarding-jsx": () => import("./../../../src/pages/onboarding.jsx" /* webpackChunkName: "component---src-pages-onboarding-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-use-cases-enterprises-jsx": () => import("./../../../src/pages/use-cases/enterprises.jsx" /* webpackChunkName: "component---src-pages-use-cases-enterprises-jsx" */),
  "component---src-pages-use-cases-smes-jsx": () => import("./../../../src/pages/use-cases/smes.jsx" /* webpackChunkName: "component---src-pages-use-cases-smes-jsx" */)
}

